
body {
  font-family: 'Inter', sans-serif !important;
}
div{
  font-family: 'Inter', sans-serif!important;
}
p{
  font-family: 'Inter', sans-serif!important;
}
a{
  font-family: 'Inter', sans-serif!important;
}
#root,
.app,
.content{
  height: 100%;
  width: 100%;
}

.app{
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
